<template>
  <div class="root mt-5 text-center px-5">
    <NotificationFlowModal
        :dialogClass="dialogClass"
        :dialogHeading="dialogHeading"
        :dialogIconSrc="dialogIconSrc"
        :dialogInstruction="dialogInstruction"
        :dialogActionText="dialogActionText"
        :formError="formError()"
        :formErrorMessage="formErrorMessage()"
        @dialogAction="dialogAction"
        @handleBackToPortalLogin="handleBackToPortalLogin"
        ref="notificationFlowModal"
    />
  </div>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import FormMixin from '@/mixins/formMixin'
  import ClickToCallMain from "../components/ClickToCallMain";
  import NotificationFlowModal from "@/components/NotificationFlowModal";

  export default {
    data: function() {
      return {
        dialog: null,
        type: null,
        dialogClass: null,
        dialogIconSrc: null,
        dialogHeading: "Next Step:",
        dialogInstruction: null,
        dialogActionText: null,
        dialogActionHref: "#",
        dialogAction: this.closeModal,
        success: false,
        intendedRoute: null,
        responseAuthStatus: null
      }
    },
    name: 'question-response',
    components: {
      ClickToCallMain,
      NotificationFlowModal
    },
    mixins: [FormMixin],
    methods: {
      isFullyAuthenticated() {
        // This corresponds to a check in SAM for the user's required authentication type to be `PortalAuthenticationRequirementType.NONE`.
        // Otherwise the displayed modal message on Portal may not match the required authentication type on SAM.
        return this.$root.authStatus.authTypeRequired === 'NONE';
      },
      setupConfirmRtwYes() {
        this.dialogClass = "terminal-flow";
        this.dialogIconSrc = "/img/congrats-check-1.svg";
        this.dialogHeading = "Thank you for your response";
        this.dialogInstruction = "";
        this.dialogActionText = "Close";
        this.dialogAction = this.handleBackToPortalLogin;
      },
      setupConfirmRtwNo() {
        this.dialogClass = "terminal-flow";
        this.dialogIconSrc = "/img/congrats-check-1.svg";
        this.dialogHeading = "Thank you for informing us.";
        this.dialogInstruction = "Your program administrator will be in touch.";
        this.dialogActionText = "Close";
        this.dialogAction = this.handleBackToPortalLogin;
      },
      setupBirthConfirmationArrived(leaveId, taskId) {
        const route = this.$root.getRouteByName('todo');
        route.query = { 'defaultAction': 'birthConfirmation', 'taskId': taskId };
        this.intendedRoute = route;

        this.dialogClass = "login-required-flow";
        this.dialogIconSrc = null;
        this.dialogAction = this.handleSetIntendedRouteAndTriggerAuthStatusUpdate;

        if (!this.isFullyAuthenticated()) {
          this.dialogHeading = "We've sent you a validation code";
          this.getAuthMethodAndUpdateDialogInstruction(this.responseAuthStatus.portalUserId);
        } else {
          this.dialogHeading = "Next Step:";
          this.dialogInstruction = "Continue to the portal and complete the Birth Confirmation form.";
        }
        this.dialogActionText = "Complete Birth Confirmation";
      },
      setupBirthConfirmationNotYet() {
        this.dialogClass = "terminal-flow";
        this.dialogIconSrc = "/img/congrats-check-1.svg";
        this.dialogHeading = "Thank you for your response!";
        this.dialogInstruction = "We will check back in at later date.";
        this.dialogActionText = "Close";
        this.dialogAction = this.handleBackToPortalLogin;
      },
      setupBirthConfirmationContactMe() {
        this.dialogClass = "terminal-flow";
        this.dialogIconSrc = "/img/congrats-check-1.svg";
        this.dialogHeading = "Thank you for your response!";
        this.dialogInstruction = "Your program administrator will reach out to you to request more information.";
        this.dialogActionText = "Close";
        this.dialogAction = this.handleBackToPortalLogin;
      },
      showModal() {
        if (!this.dialog) {
          console.error("No dialog");
          return;
        }

        this.dialog.showModal();
      },
      closeModal() {
        if (!this.dialog) {
          console.error("No dialog");
          return;
        }

        this.dialog.close();
      },
      handleBackToPortalLogin() {
        this.closeModal();
        this.$router.push({name: this.$constants.LoginRouteNames.EMPLOYEE});
      },
      handleSetIntendedRouteAndTriggerAuthStatusUpdate() {
        this.closeModal();
        this.$root.intendedRoute = this.intendedRoute;

        const shouldNavigateToDefaultRoute = this.$root.authStatus.portalUserId === this.responseAuthStatus.portalUserId;
        this.$root.updateAuthStatus()
          .then(response => {
            if (shouldNavigateToDefaultRoute) {
              this.$root.navigateToDefaultRoute();
            }
          });
      },
      validateToken() {
        this.submit('post', ApiProperties.api.questionResponse, {}, {
            token: this.$route.query.token
          },
          (response) => {
            this.success = true;
            const authenticationStatus = response.data.authenticationStatus;
            this.responseAuthStatus = authenticationStatus;
            const tokenResponse = response.data;
            const emailToPortalFlow = response.data.emailToPortalFlow;
            switch (emailToPortalFlow.type) {
              case "CONFIRM_UPCOMING_LEAVE_START_YES":
              case "CONFIRM_UPCOMING_RTW_YES":
              case "CONFIRM_RTW_YES":
                this.setupConfirmRtwYes();
                break;
              case "CONFIRM_UPCOMING_LEAVE_START_NO":
              case "CONFIRM_UPCOMING_RTW_NO":
              case "CONFIRM_RTW_NO":
                this.setupConfirmRtwNo();
                break;
              case "BIRTH_CONFIRMATION_ARRIVED":
                const leaveId = emailToPortalFlow.leaveId;
                let taskId;
                // If there is no taskId in emailToPortalFlow, the JWT was likely generated in the old format whereby
                // no taskId was included as a claim in the JWT. In this format the taskId is a query parameter.
                taskId = emailToPortalFlow.taskId ? emailToPortalFlow.taskId : this.$route.query.taskID;

                this.setupBirthConfirmationArrived(leaveId, taskId);
                break;
              case "BIRTH_CONFIRMATION_NOT_YET":
                this.setupBirthConfirmationNotYet();
                break;
              case "BIRTH_CONFIRMATION_CONTACT_ME":
                this.setupBirthConfirmationContactMe();
                break;
              default:
                console.log(`Unrecognized flow: ${emailToPortalFlow.type}`);
                break;
            }

            this.showModal();
          }, () => {
            this.showModal();
          });
      },
      getAuthMethodAndUpdateDialogInstruction(portalUserId) {
        const pathParams = { pathVars: { portalUserId: portalUserId } };
        this.$http.get(ApiProperties.api.user, pathParams)
          .then(response => {
            const user = response.data;

            if (user.authMethod === 'SMS' && user.phoneLastFourDigits) {
              this.dialogInstruction = `Validation code sent to the phone number ending in ${user.phoneLastFourDigits}.`;
            } else if (user.authMethod === 'EMAIL' && user.emailAddress) {
              this.dialogInstruction = `Validation code sent to your email address.`;
            }

            this.dialogInstruction += '\nClick below to log in with your validation code.';
          })
          .catch((error) => {
            console.log(error)
          });
      }
    },
    mounted: function() {
      this.dialog = this.$refs.notificationFlowModal;
      this.validateToken();
    }
  }
</script>

<style scoped>
  @media only screen and (max-width: 575.98px) {
    .root {
      padding: 0;
    }
  }
</style>
